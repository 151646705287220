import React, { useEffect } from "react"
import {graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"

import Recurring from "../images/Recurring_invoices.inline.svg"
import Sendout from "../images/Send_out_icon.inline.svg"
import Emailicon from "../images/emailicon.inline.svg"

import Emailimage from "../images/emailimage.inline.svg"

function ByEmail({ data, pageContext: { lang, currentPath } }) {
  useEffect(() => {

  }, [])
  return (
  <Layout>
    <SEO title="Home" />
  <div className="mobile-order-by-email">
      <Container className="above">
        <Row>
          <Col>
        <div className="above-tag"><Emailicon/><span>By email</span></div>
        <h1><strong>Are you a freelancer</strong><br /> looking to accept the Dagcoin cryptocurrency?</h1>
        <p>This is the solution for you!</p>
        </Col>
        <Col className="images-above">
        <div className="aboveone emailimageone hide-mobile"><Emailimage /></div>
        <div className="aboveone emailimageone show-mobile"><Img fluid={data.fm.childImageSharp.fluid} /></div>
        </Col>
        </Row>
      </Container>
      <Container className="above-after">
        <Row>
          <Col className="images-after">
            <div className="aboveafterthree"><Img fluid={data.invoiceimage.childImageSharp.fluid} /></div>
          </Col>
          <Col className="after-content">
            <h2>The email invoicing feature is designed to match your needs</h2>
            <div className="mobileonly"><Img fluid={data.sm.childImageSharp.fluid} /></div>
            <ul>
              <li><Sendout/>Send out single invoices</li>
              <li><Recurring/>Send out recurring invoices with specific intervals</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="overview">
        <Row><h3>An overview of all single and recurring email invoices can be easily viewed and managed in the web office</h3></Row>
      </Container>
      <Container className="fullwidth">
      <div className="emailimage hide-mobile">
        <Img fluid={data.emailimage.childImageSharp.fluid} />
        </div>
        <div className="emailimage show-mobile">
        <Img fluid={data.tm.childImageSharp.fluid} />
        </div>
        </Container>
        </div>
  </Layout>
  )
}
export const query = graphql`
  query {
    invoiceimage: file(relativePath: { eq: "invoiceimages.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bymailupper: file(relativePath: { eq: "bymailupper.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    emailimage: file(relativePath: { eq: "email.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    fm: file(relativePath: { eq: "1m.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sm: file(relativePath: { eq: "2m.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tm: file(relativePath: { eq: "3m.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageone: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagetwo: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    imagethree: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    imagefour: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default ByEmail
